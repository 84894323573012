export const fonImageSizeDefaultLaptop = {
    width: 2400,
    height: 1080
};

export const fonImageSizeDefaultLMobile = {
    width: 250,
    height: 500
};

export const soundsResources = [
    {
        name: 'click',
        url: 'sounds/click.mp3'
    },
    {
        name: 'close',
        url: 'sounds/close.mp3'
    },
    {
        name: 'ui-open',
        url: 'sounds/ui-open.mp3'
    },
    {
        name: 'door-pre-open',
        url: 'sounds/door-pre-open.mp3'
    },
    {
        name: 'door-open',
        url: 'sounds/door-open.mp3'
    },
    {
        name: 'case-open',
        url: 'sounds/case-open.wav'
    },
    {
        name: 'case-close',
        url: 'sounds/case-close.mp3'
    },
    {
        name: 'login-success',
        url: 'sounds/login-success.mp3'
    },
    {
        name: 'sweep-transition',
        url: 'sounds/mixkit-fast-small-sweep-transition.mp3'
    },
    {
        name: 'news-action',
        url: 'sounds/news-action.mp3'
    },
    {
        name: 'news-open',
        url: 'sounds/news-open.mp3'
    },
    {
        name: 'comics-open',
        url: 'sounds/comics-open.mp3'
    },
    {
        name: 'hologram-open',
        url: 'sounds/hologram-open.mp3'
    },
    {
        name: 'hologram-close',
        url: 'sounds/hologram-close.mp3'
    },
    {
        name: 'reserve-action-swipe',
        url: 'sounds/reserve-action-swipe.mp3'
    },
    {
        name: 'rocket',
        url: 'sounds/rocket.mp3'
    },
    {
        name: 'machine',
        url: 'sounds/machine.mp3'
    },
    {
        name: 'tablet',
        url: 'sounds/tablet.mp3'
    },
    {
        name: 'lamp',
        url: 'sounds/lamp.mp3'
    },
    {
        name: 'planet-1',
        url: 'sounds/planet-1.mp3'
    },
    {
        name: 'planet-2',
        url: 'sounds/planet-2.mp3'
    },
    {
        name: 'planet-3',
        url: 'sounds/planet-3.mp3'
    },
    {
        name: 'spaceport-1',
        url: 'sounds/spaceport-1.mp3'
    },
    {
        name: 'spaceport-2',
        url: 'sounds/spaceport-2.mp3'
    },
    {
        name: 'spaceport-3',
        url: 'sounds/spaceport-3.mp3'
    },
];

export const hostName = 'https://unit1102.p-host.kiev.ua';