export const imageList = [
	{	
		name: 'door',
		url: 'images/first-room/door.png',
		urlActive: 'images/first-room/door-active.png'
	},
	{
		name: 'machine',
		url: 'images/first-room/machine.png',
		urlActive: 'images/first-room/machine-active.png'
	},
	{	
		name: 'table',
		url: 'images/first-room/table.png',
		urlActive: null
	},
	// {
	// 	name: 'comics',
	// 	url: 'images/first-room/comics.png',
	// 	urlActive: 'images/first-room/comics-active.png'
	// },
	{
		name: 'hologram',
		url: 'images/first-room/hologram.png',
		urlActive: 'images/first-room/hologram-active.png',
	},
	{	
		name: 'lamp',
		url: 'images/first-room/lamp.png',
		urlActive: 'images/first-room/lamp-active.png'
	},
	{	
		name: 'case',
		url: 'images/first-room/case.png',
		urlActive: 'images/first-room/case-active.png'
	}
];

export const melodies = [
	'spaceport-1',
	'spaceport-2',
	'spaceport-3',
];

export const fonURL = 'images/first-room/back.png';
export const messageURL = 'images/first-room/message.png';
export const fonMobileURL = 'images/first-room/back-mobile.png';