import React from "react";
import { useState, useEffect } from "react";
import './coming_soon_tooltip.css';

const ComingSoonTooltip = ({visible,setVisible}) => {
    useEffect(() => {
        setTimeout(() => {
            setVisible(false);
        }, 1000);
    },[]);
    return (
     
        <div className={ visible ? 'lamp-tooltip' : 'display-none'} >
            <h4>On my way - coming soon!</h4>
        </div>
       
    )
};
export default ComingSoonTooltip;