import axios from 'axios';
import { hostName } from '../constants/main';

export const get = (path, readed) => (
	axios
		.get(hostName + '/' + path)
		.then(res => readed({
			isSuccess: true,
			data: res.data
		}))
		.catch(err => readed({
			isSuccess: false,
			data: err
		}))
);	