import { useEffect, useRef, useState, useContext, useCallback } from 'react';
import HTMLFlipBook from 'react-pageflip';
import { screenContext } from '../../../../constants/screen-context';

import { hostName } from '../../../../constants/main';
import 'animate.css';
import {
	fonURL,
	clipList,
	logoURL,
	btn_close,
	selected_ep
} from '../../../../constants/flip-book';
import {
	isMobileSize
} from '../../../../services/main';
import {
	get
} from '../../../../services/server-data';

const bookObj = {
	firstPage: 'translateX(-250px)',
	lastPage: 'translateX(250px)',
	anyPage: 'translateX(0)'
};

const FlipBookLaptop = ({active, setActive}) => {
	const [comicsList, setComicsList] = useState([]);
	const [activeIndex, setActiveIndex] = useState(0);
	const [imageList, setImageList] = useState([]);
	const [bookWrapperStyle, setBookWrapperStyle] = useState({
		transform: bookObj.firstPage
	});
	
	const flipBookRef = useRef(null);	
	const { appRef, screenSize } = useContext(screenContext);
	const isMobile = isMobileSize(screenSize);

	useEffect(() => {
		document.addEventListener("keydown", function(event) {
		   
			if (event.keyCode ===27) {
				
				setActive(false);
			};
		   
		  })},[]);

	useEffect(() => {
		get('comics', ({ isSuccess, data }) => {
			if (isSuccess) {
				setComicsList(data);
				if (data.length) {
					setActiveIndex(data.length);
					setImageList(data[data.length - 1].images);
				}
			}
		});

		//appRef.current.style = 'height: 100%';
		
		//return () => appRef.current.style = 'height: 100vh';
	}, []);
	
	const clipSelect = (id, images) => {
		setActiveIndex(id);		
		setImageList(images);
		setBookWrapperStyle({transform: bookObj.firstPage});
		flipBookRef.current.pageFlip().turnToPage(0);
	};
	
	const onFlipHandler = useCallback(e => {
		if (!flipBookRef.current) {
			return;
		}
		
		const pageCount = flipBookRef.current.pageFlip().getPageCount();
		
		if (e.data === 0) {
			setBookWrapperStyle({
				transform: bookObj.firstPage
			});			
			return;
		}
		
		if (e.data === pageCount - 1) {
			setBookWrapperStyle({
				transform: bookObj.lastPage
			});			
			return;
		}
		
		setBookWrapperStyle({
			transform: bookObj.anyPage
		});
	}, []);
	
	return (
		
				<section className={active ? "flip-box-active" : "flip-box-not-active"}>
		<section className={"page-flip-laptop"}>
			<div
				className="page-flip-laptop__fon"
				src={fonURL}
				alt="fon"
			/>
			
			<div className="top_info_comics animate__animated animate__bounceInDown">
			<div
				className="title animation animation_bounce"
				
				>
            	
                	COMICS
                
				</div>
				<div className='episodes'>
					<ul className="page-flip-laptop__items">
					{ comicsList.map((item, index) => {
						const { id, name, images } = item;					
						
						if (id === activeIndex) {
							return (

							<div className='page-flip-laptop__item_div page-flip-laptop__item_active_div' key={index}>
								<img
								className="page-flip-laptop__item_div page-flip-laptop__item_active_div_img"
								src={selected_ep}
								alt="fon"
								/>
								<li
									className="page-flip-laptop__item page-flip-laptop__item_active"
								>
									{name}
								</li>
							</div>
						)
					} else {					
						return (
							<li
								className="page-flip-laptop__item"
								key={index}
								onClick={() => clipSelect(id, images)}
							>
								{name}
							</li>
						)
					}
					}) }
				</ul>
				</div>
							<a
							className="close animate__animated animate__bounceInDown"
							onClick={() => setActive(false)}
							/>
            </div>
		
		
			<div
				className="page-flip-laptop-book-wrapper"
				style={bookWrapperStyle}
			>
				<HTMLFlipBook
					ref={flipBookRef}
					minHeight={300}
					maxHeight={750}
					minWidth={200}
					maxWidth={500}
					width={500}
					height={750}
					size={'stretch'}
					drawShadow={true}
					autoSize={true}
					showCover={true}
					onFlip={onFlipHandler}
				>					
					{ imageList.map((imageURL, index) => 
						<div key={index} className="demoPage">
							<img
								className="demoPage__image"
								src={hostName + imageURL}
								alt="comics"
							/>
							{index === 0 &&
								<div className="click-here-btn">click here</div>
							}
						</div>
					)}
				</HTMLFlipBook>
			</div>
		</section>
		</section>
		
	);
};

export default FlipBookLaptop;