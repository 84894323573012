import {useEffect, useState} from 'react';

import { hostName } from '../../constants/main';
import { get } from '../../services/server-data';

const Info = ({ active, setActive, soundCloseRef }) => {
	const [noteList, setNoteList] = useState([]);
	const [settings, setSettings] = useState([{}]);
	const [activeCategory, setActiveCategory] = useState();
	const [activeNote, setActiveNote] = useState();
	const [infoBlock, setInfoBlock] = useState({});

	useEffect(() => {
		document.addEventListener("keydown", function(event) {
		   
			if (event.keyCode ===27) {
				soundCloseRef.current.play();
				setActive(false);
			};
		   
		  })},[]);

	useEffect(() => {
		get('notes1', ({ isSuccess, data }) => {
			if (isSuccess) {
				setNoteList(data);
				if (data.length) {
					setActiveCategory(data[0].id);
					if (data[0].notes.length) {
						setActiveNote(data[0].notes[0].id);
						setInfoBlock({
							name: data[0].notes[0].name,
							textArea: data[0].notes[0].textarea,
							imageUrl: hostName + data[0].notes[0].imageUrl
						});
					}
				}
			}
		});

		get('settings', ({ isSuccess, data }) => {
			if (isSuccess) {
				setSettings(data);
			}
		});
	}, []);
	
	if (noteList.length === 0) {
		return null;
	}
	
	const navCategoryClickHandler = id => {
		setActiveCategory(id);
	};

	const navSubCategoryClickHandler = (id, name, textArea, imageUrl) => {
		setActiveNote(id);
		setInfoBlock({
			name,
			textArea,
			imageUrl
		});
		document.getElementById('info_content').scrollTop = 0;
	};
	
	return (
		<section className={active ? "body_info active" : "body_info"}>
			<div className="container_info">
				<div className="top_info">
					<div className="title">
						INFO
					</div>
					
					<div
						className="close"
						onClick={() => {
							soundCloseRef.current.play();
							setActive(false);
						}}
					/>
				</div>
				
				<div className="bottom_info">				
					<div className="nav_info">					
						<nav className="nav_wrap">
							{ noteList.map((item) => {
								const id = item.id;
								const name = item.name;
								const notes = item.notes;
								
								return (
									<div
										className={activeCategory === id ? "nav_link hover" : "nav_link"}
										key={id}
									>
										<a
											className="link"
											onClick={() => navCategoryClickHandler(id)}
										>
											<span>{name}</span>
										</a>

										<div className="subcategory_list">
											{ notes.map((item) => {
												const id = item.id;
												const name = item.name;
												const textArea = item.textarea;
												const imageUrl = hostName + item.imageUrl;

												return (
													<a
														className={activeNote === id ? "subnav_link active" : "subnav_link"}
														key={id}
														onClick={() => navSubCategoryClickHandler(id, name, textArea, imageUrl)}
													>
														<span>{name}</span>
													</a>
												)
											}) }
										</div>
									</div>
								)
							}) }
						</nav>

						<div className="social_list">
							<a href={settings[0].discord} target='_blank' className="soc_link soc_discord"/>
							<a href={settings[0].twitter} target='_blank' className="soc_link soc_twitter"/>
							<a href={settings[0].website} target='_blank' className="soc_link soc_bank"/>
							<a href={settings[0].telegram} target='_blank' className="soc_link soc_telegram"/>
						</div>
					</div>
					
					<div className="text_info">
						<div className="border">
							<div className="content" id="info_content">
								<img src={infoBlock.imageUrl} alt="" />
								{/*<h1 className="content-title">{infoBlock.name}</h1>*/}
								<div className="content-text" dangerouslySetInnerHTML={{__html: infoBlock.textArea}} />
							</div>
						</div>
					</div>
				</div>			
			</div>
		</section>
	);
};

export default Info;