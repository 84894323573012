import { 
	fonImageSizeDefaultLaptop,
	fonImageSizeDefaultLMobile
} from '../../../constants/main';

const PlayBox = ( props ) => {
	let style = {};
	
	const screenSize = props.screenSize;
	
	if (!screenSize) {
		return;
	}
	
	const screenK = screenSize.width / screenSize.height;
	
	if (screenSize.width >= screenSize.height) {
		// Laptop screen
			const imageK = fonImageSizeDefaultLaptop.width / fonImageSizeDefaultLaptop.height;
			const imageHeight = screenSize.height;
			const imageWidth = Math.floor(imageK * imageHeight);
			const delta = Math.floor((imageWidth - screenSize.width) / 2);
			
			style = {
				width: imageWidth + 'px',
				height: imageHeight + 'px',
				left: -delta + 'px'
			};
	} else {
		// Mobile screen
		const imageK = fonImageSizeDefaultLMobile.width / fonImageSizeDefaultLMobile.height;
		const imageWidth = screenSize.width;
		const imageHeight = Math.floor(imageWidth / imageK);
		const delta = Math.floor((imageHeight - screenSize.height) / 2);
		
		style = {
			width: imageWidth + 'px',
			height: imageHeight + 'px',
			top: -delta + 'px'
		};
	}
	
	return (
		<section
			className="play-box"
			style={style}
		>
			{props.children}
		</section>
	);
};

export default PlayBox;