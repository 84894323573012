import React from 'react';
import {useContext, useEffect} from 'react';
import {screenContext} from '../../../constants/screen-context';
import fox from '../../../img/login/Icn_MetaMask_Login.png';
import wallet_connect from '../../../img/login/Icn_WalletConnect_Ldng.png';
import safepal from '../../../img/login/Icn_SafePal_Ldng.png';
import coinbase from '../../../img/login/Icn_Coinbase_Ldng.png';
import trust from '../../../img/login/Icn_Trust_Ldng.png';
import binance from '../../../img/login/Icn_Binance_Ldng.png';
import {isMobileSize} from '../../../services/main';
import PlayBox from '../../_commonComponents/PlayBox/PlayBox';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';

const LoginPopup = ({ visible, setVisible, setIsLoginPopupActive, soundCloseRef }) => {
    const {screenSize} = useContext(screenContext);
    const isMobile = isMobileSize(screenSize);

    useEffect(() => {
        document.addEventListener("keydown", function(event) {
           
        if (event.keyCode === 27) {
            soundCloseRef.current.play();
            setVisible(false);
        };

      })},[]);

    if (isMobile === null) {
		return;
	}

    const provider = new WalletConnectProvider({
        rpc: {
            1: "https://cloudflare-eth.com/", // https://ethereumnodes.com/
            56: "https://bsc-dataseed.binance.org/",
            137: "https://polygon-rpc.com/", // https://docs.polygon.technology/docs/develop/network-details/network/
        }
    });

    const connect = async () => {
        setIsLoginPopupActive(false);
        try {
            await provider.enable();
            const web3 = new Web3(provider);
            const accounts = await web3.eth.getAccounts();
            sessionStorage.setItem("Walletconnect", accounts);
            sessionStorage.removeItem("Metamask");
        } catch {}
    };

    const clickHandler = name => {
        if (name === "MetaMask") {
            if (window.ethereum) {
                window.ethereum.request({method:'eth_requestAccounts'})
                    .then(res => {
                    // Return the address of the wallet
                    if (res[0]) {
                        sessionStorage.setItem("Metamask", res[0]);
                        sessionStorage.removeItem("Walletconnect");
                        //window.location.reload();
                    } else {
                        alert("Login was not success.");
                    }
                })
            } else {
                alert("You need to have metaMask extension installed in your browser. Install extesion and try again.");
            }
            setIsLoginPopupActive(false);
        } else if (name === "WalletConnect") {
            connect();
        }
    };

    return(
        <div
            style={
              visible
                ? {display: 'block', position: 'absolute',}
                : { display: 'none' }
            }
          >
            <PlayBox
                screenSize={screenSize}
            >
            <div className={"background_fon"}>
            <div className="top_info_login">
                        <div className="title">
                            LOGIN
                        </div>
                        <a
                            className="close"
                            onClick={() => {
                                soundCloseRef.current.play();
                                setVisible(false);
                            }}
                        />
                    </div>
                <div className="login_cards">
                    <div className="login_card" onClick={() => clickHandler('MetaMask')}>
                        <img
                        className="login_card_logo"
                        src={fox}
                        alt='MetaMask'/>
                        <h3 className='login_card_title'>MetaMask</h3>
                        <h4 className="login_card_info">MetaMask Wallet</h4>
                    </div>
                    <div className="login_card" onClick={() => clickHandler('WalletConnect')}>
                        <img
                        className="login_card_logo"
                        id="MetaMaskCard"
                        src={wallet_connect}
                        alt='WalletConnect'/>
                        <h3 className='login_card_title'>Wallet Connect</h3>
                        <h4 className="login_card_info">Scan your<br/> WalletConnect to Conect</h4>
                    </div>
                    <div className="login_card_inactive">
                        <img
                         className="login_card_logo"
                         src={safepal}
                        alt='Safepal'/>
                        <h3 className='login_card_title'>Safepal</h3>
                        <h4 className="login_card_info">Coming Soon</h4>
                    </div>
                    <div className="login_card_inactive">
                        <img
                         className="login_card_logo"
                         src={coinbase}
                         alt='CoinbaseWallet'/>
                        <h3 className='login_card_title'>Coinbase Wallet</h3>
                        <h4 className="login_card_info">Coming Soon</h4>
                    </div>
                    <div className="login_card_inactive">
                        <img
                         className="login_card_logo"
                         src={trust}
                        alt='Trust'/>
                        <h3 className='login_card_title'>Trust</h3>
                        <h4 className="login_card_info">Coming Soon</h4>
                    </div>
                    <div className="login_card_inactive">
                        <img
                         className="login_card_logo"
                         src={binance}
                         alt='Binance'/>
                        <h3 className='login_card_title'>Binance</h3>
                        <h4 className="login_card_info">Coming Soon</h4>
                    </div>
                </div>
            </div>
            </PlayBox>
        </div>
    )
};
export default LoginPopup;