import { useState, useEffect, useRef, useContext } from 'react';
import './_preloader.scss';
import PlayBox from '../PlayBox/PlayBox';
import { screenContext } from '../../../constants/screen-context';
import SpriteAnimation from '../../_commonComponents/SpriteAnimation/SpriteAnimation';

const Preloader = ({ duration, active, setActive }) => {
    const progressbarRef = useRef(false);
    const { screenSize } = useContext(screenContext);

    useEffect(() => {
        let width = 0;

        const interval = setInterval(() => {
            width += 1000 / duration;
            progressbarRef.current.style.width = width.toFixed(3) + '%';
        }, 10);

        setTimeout(() => {
            clearInterval(interval);
            setActive(false);
        }, duration);
    }, []);

    return (
        <div className={active ? "preloader active" : "preloader"}>
            <PlayBox screenSize={screenSize} >
                <img className="preloader-background" src="images/home-page.png" alt="" />
                <img className="preloader-door" src="images/door_not_active.png" alt="" />
                <div className="preloader-overlay" />
                <SpriteAnimation
						active={true}
						visible={true}
						scene={1}
						itemProps={{
							name: 'loadingback',
							className: 'loading_back_animation',
							url: 'images/sprites/Loading.png',
							frames: 15,
							clock: 40,
							duration: 0,
							interval: 0,
						}}
						
					/>
                <img className="preloader-bg" src="images/LOADING_text.png" />
                <div className="preloader-progress">
                    <div className="preloader-progressbar" ref={progressbarRef} />
                </div>
            </PlayBox>
        </div>
    );
}

export default Preloader;