import React, { useState } from "react";
import { Popover } from 'antd';
import './case_cutting_popup.css';
import card from '../../img/card.png';
import card_active from '../../img/frame_active_card.png';
import tile from '../../img/tile.jpg'

const Card = ({ type, maretial, element, level, durability, index, currentActive, setCurrentActive, image, setConfig, name}) => {

    //const [activeCard, setActiveCard] = useState( currentActive == index ? 1 : 0 );

    return (
        <Popover  zIndex='1000000' mouseEnterDelay={0.1} mouseLeaveDelay={0.001}
            trigger="hover" color={'rgba(11, 12, 34, 0.9)'} 
            content={
                <div className='popover-div'>
                    <h2>{name}</h2>
                    <div className="popover-options">
                        <p className="default_p">Type:</p>
                        <p className="special_p">{type}</p>
                    </div>
                    <div className="popover-options">
                        <p className="default_p"> Marerial:</p>
                        <p className="special_p"> {maretial}</p>
                    </div>
                    <div className="popover-options">
                        <p className="default_p">Element:</p>
                        <p className="element_p">{element}</p>
                    </div>
                    <div className="popover-options">
                        <p className="default_p">Level:</p>
                        <p className="special_p">{level}</p>
                        </div>
                    <div className="popover-options">
                        <p className="default_p">Durability:</p>
                        <p className="special_p">{durability}</p>
                    </div>
                </div>
            }
        >
            <div className={"card"}>
                <img className={'card_img'}
                     src={image ? image : currentActive === index ? image : card}
                     alt=""
                     onClick={() => {
                        setCurrentActive(index)
                    }}
                />
                <img className={currentActive === index ? 'card_active' : 'display-none-card'}
                src={card_active}
                alt="card_active"
               
                />
               <img className={'card_active_bone'}
                src={card_active}
                alt="card_active"
               
                />
            </div>
            
            {/*<div className={currentActive === index ? "card_active" : "card"}>
                <img className={'card_img'}
                     src={currentActive === index ? card_active : card}
                     alt="card"
                     onClick={() => {
                        setCurrentActive(index)

                    }}
                />
            </div>*/}
        </Popover>
    )
};
//done

export  default Card;