import {useState, useEffect, useRef, useContext} from 'react';
import { screenContext } from '../../../constants/screen-context';

const SpriteAnimation = ({ itemProps, active, visible, clicked, scene, revert }) => {
    const {
        name,
        className,
        url,
        frames,
        clock,
        duration,
        interval,
        hover,
        stopAtEnd
    } = itemProps;

    const ref = useRef(false);
    const sprite = useRef(false);
    const [isVisible, setIsVisible] = useState(visible);
    const [style, setStyle] = useState({});
    const [loop, setLoop] = useState(null);
    const [intervalLoop, setIntervalLoop] = useState(null);
    const { screenSize } = useContext(screenContext);

    const clickHandler = () => {
        clicked(name);
    };

    const mouseEnterHandler = () => {
        if (hover) {
            sprite.current.style.backgroundPositionY = ref.current.offsetHeight + 'px';
        }
    }

    const mouseLeaveHandler = () => {
        if (hover) {
            sprite.current.style.backgroundPositionY = 0;
        }
    }

    useEffect(() => {
        const width = ref.current.offsetWidth;
        const height = ref.current.offsetHeight;

        let offset = revert ? width * frames : 0;

        setStyle({
            width: width,
            height: height,
            backgroundImage: 'url("' + url + '")',
            backgroundSize: width * frames + 'px ' + (hover ? height * 2 : height) + 'px',
            backgroundPositionX: offset,
            backgroundPositionY: 0
        });

        setIsVisible(visible);

        if (active) {
            if (loop)
                clearInterval(loop);
            if (intervalLoop)
                clearInterval(intervalLoop);

            setLoop(setInterval(() => {
                if (revert) {
                    offset -= width;
                    if (offset <= 0) {
                        if (stopAtEnd) {
                            offset = width;
                        } else {
                            offset = width * frames;
                        }
                    }
                } else {
                    offset += width;
                    if (offset >= width * frames) {
                        if (stopAtEnd) {
                            offset = width * (frames - 1);
                        } else {
                            offset = 0;
                        }
                    }
                }

                setStyle(prevState => ({
                    ...prevState,
                    backgroundPositionX: -offset
                }));
            }, clock));

            if (duration) {
                if (interval) {
                    setIsVisible(false);
                    setIntervalLoop(setInterval(() => {
                        offset = 0;
                        setIsVisible(true);
                        setTimeout(() => {
                            setIsVisible(false);
                        }, duration);
                    }, interval));
                } else {
                    setTimeout(() => {
                        setIsVisible(false);
                    }, duration);
                }
            }
        } else {
            if (loop)
                clearInterval(loop);
            if (intervalLoop)
                clearInterval(intervalLoop);
        }
    }, [active, visible, scene, screenSize, revert]);

    return (
        <div
            className={isVisible ? `sprite-animation visible ${active ? 'active ' : ''}` + className : `sprite-animation ${active ? 'active ' : ''}` + className}
            ref={ref}
        >
            <div
                style={style}
                ref={sprite}
                onClick={clickHandler}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
            />
            <img src={url} style={{display: 'none'}} />
        </div>
    );
}

export default SpriteAnimation;