import { useState, useRef, useContext, useEffect } from 'react';
import PlayBox from '../_commonComponents/PlayBox/PlayBox';
import { screenContext } from '../../constants/screen-context';
import Preloader from '../_commonComponents/Preloader/Preloader';
import FirstRoom from '../FirstRoom/FirstRoom';
import LoginPopup from '../_commonComponents/LoginPopup/LoginPopup';
import SpriteAnimation from '../_commonComponents/SpriteAnimation/SpriteAnimation';
import FlipBookLaptop from '../_commonComponents/FlipBook/FlipBookLaptop/FlipBookLaptop';
import MachinePopup from '../_commonComponents/MachinePopup/MachinePopup';
import Info from '../Info/Info';
import CasePopup from '../CaseCutting/CasePopup';
import DeckPopup from '../CaseCutting/DeckPopup';

import {
	fonURL,
	fonMobileURL,
	spriteList
} from '../../constants/home-page';
import {
	isMobileSize,
	getSound
} from '../../services/main';
import ComingSoonTooltip from '../_commonComponents/ComingSoonTooltip/ComingSoonTooltip';

const Home = () => {
	const { screenSize } = useContext(screenContext);
	const isMobile = isMobileSize(screenSize);
	const [doorAnimation, setDoorAnimation] = useState(false);
	const [doorIsOpen, setDoorIsOpen] = useState(false);
	const [isPreloading, setIsPreloading] = useState(true);
	const [isHomeActive, setIsHomeActive] = useState(true);
	const [isRoomActive, setIsRoomActive] = useState(false);
	const [isLoginPopupActive, setIsLoginPopupActive] = useState(false);
	const [isMachinePopupActive, setIsMachinePopupActive] = useState(false);
	const [isCasePopupActive, setIsCasePopupActive] = useState(false);
	const [isDeckPopupActive, setIsDeckPopupActive] = useState(false);
	const [flipBookActive, setFlipBookActive] = useState(false);
	const [infoActive, setInfoActive] = useState(false);
	const [scene, setScene] = useState(0);
	const [isLampActive,setIsLampActive] = useState(false);
	const [isMusicMuted, setIsMusicMuted] = useState(false);
	const [deckData, setDeckData] = useState({});
	const [currentActiveDeck, setCurrentActiveDeck] = useState(null);

	const loadingRef = useRef(null);
	const doorRef = useRef(null);
	const doorInnerRef = useRef(null);
	const closedDoorRef = useRef(null);
	const buttonsRef = useRef(null);
	const lightsRef = useRef(null);
	const lightLeftRef = useRef(null);
	const lightRightRef = useRef(null);
	const soundInfoRef = useRef(null);
	const soundDoorRef = useRef(null);
	const soundDoorHoverRef = useRef(null);
	const soundCloseRef = useRef(null);
	const soundCaseCloseRef = useRef(null);
	const soundKeysBlockRef = useRef(null);
	const soundMusicRef = useRef(null);

	const [currentCaseConfig, setCaseConfig] = useState(["", "", "", ""]);

	const preloadingTime = 12000;

	const startAnimation = () => {
		setTimeout(() => {
			setTimeout(() => {
				lightLeftRef.current.style.opacity = 1;
				lightRightRef.current.style.opacity = 1;
				loadingRef.current.style.opacity = 0.45;
				setTimeout(() => {
					lightsRef.current.style.opacity = 1;
					loadingRef.current.style.opacity = 0.3;
					setTimeout(() => {
						buttonsRef.current.style.opacity = 1;
						doorRef.current.style.opacity = 1;
						closedDoorRef.current.style.opacity = 0;
						loadingRef.current.style.opacity = 0.2;
						setTimeout(() => {
							loadingRef.current.style.opacity = 0;
						}, 500);
					}, 1000);
				}, 1000);
			}, 1000);
		}, preloadingTime);
	};

	useEffect(() => {
		startAnimation();
	}, []);

	if (isMobile === null) {
		return;
	}

	const changeScene = scene => {
		switch (scene) {
			case 0:
				soundMusicRef.current.volume = 0.5;
				setIsRoomActive(false);
				setTimeout(() => {
					setScene(0);
					setTimeout(() => {
						setIsHomeActive(true);
					}, 100);
				}, 1000);
				break;
			case 1:
				if (!isMusicMuted) {
					setTimeout(() => {
						soundMusicRef.current.play();
					}, 1000);
				}
				soundMusicRef.current.volume = 1;
				setIsHomeActive(false);
				setTimeout(() => {
					setScene(1);
					setTimeout(() => {
						setIsRoomActive(true);
					}, 100);
				}, 1000);
				break;
		}
	};

	const doorMouseEnter = () => {
		soundDoorHoverRef.current.play();
		setDoorAnimation(true);
		doorInnerRef.current.src = 'images/door_inner_hover.png';
	}

	const doorMouseLeave = () => {
		setDoorAnimation(false);
		setTimeout(() => {
			doorInnerRef.current.src = 'images/door_inner.png';
		}, 600);
	}

	const doorClickedHandler = () => {
		soundDoorRef.current.play();
		setDoorIsOpen(true);
		setDoorAnimation(false);
		doorInnerRef.current.src = 'images/door_inner_click.png';
		setTimeout(() => {
			changeScene(1);
			setTimeout(() => {
				setDoorIsOpen(false);
			}, 600);
		}, 600);
	}

	const imageClickedHandler = name => {
		switch (name) {
			case 'info':
				soundInfoRef.current.play();
				window.open('https://docs.ziggyverse.com/', '_blank');
				// setTimeout(() => {
				// 	setInfoActive(true);
				// }, 1000);
				break;
			case 'login':
				soundInfoRef.current.play();
				setTimeout(() => {
					setIsLoginPopupActive(true);
				}, 1000);
				break;
		}
	};

	return (
		<>
			<Preloader duration={preloadingTime} active={isPreloading} setActive={setIsPreloading} />

			<section className={isHomeActive ? 'scene-container active' : 'scene-container'} style={{ display: scene === 0 ? 'block' : 'none' }}>
				<PlayBox
					screenSize={screenSize}
				>
					<section className={`home${isMobile ? ' home_mobile' : ''}`}>
						{ !isMobile
						&&
						<img
							className="home__fon"
							src={fonURL}
							alt="home"
						/>
						}

						{ isMobile
						&&
						<img
							className="home__fon"
							src={fonMobileURL}
							alt="home"
						/>
						}

						<div className="home__buttons" ref={buttonsRef}>
							{ spriteList.map((item, index) => {
								const itemProps = {
									name: item.name,
									className: 'home__' + item.name,
									url: item.url,
									frames: item.frames,
									clock: item.clock,
									duration: item.duration,
									interval: item.interval,
								};

								return (
									<SpriteAnimation
										key={index}
										active={true}
										visible={true}
										scene={scene}
										itemProps={itemProps}
										clicked={imageClickedHandler}
									/>
								)
							}) }
						</div>

						<img
							className="home__door-not-active"
							src="images/door_not_active.png"
							alt="door-not-active"
							ref={closedDoorRef}
						/>

						<div
							className="home__door_container"
							ref={doorRef}
							onClick={doorClickedHandler}
							onMouseEnter={doorMouseEnter}
							onMouseLeave={doorMouseLeave}
						>
							<div className="home__door">
								<img
									className={doorIsOpen ? 'hidden' : ''}
									src="images/door_frame.png"
									alt="door"
								/>
								<img
									className={doorIsOpen ? '' : 'hidden'}
									src="images/door_frame_click.png"
									alt="door"
								/>
								<img
									className="home__door-inner"
									src="images/door_inner.png"
									alt="door-inner"
									ref={doorInnerRef}
								/>
								<img
									style={{display: 'none'}}
									src="images/door_inner_hover.png"
								/>
								<img
									style={{display: 'none'}}
									src="images/door_inner_click.png"
								/>
							</div>
							<SpriteAnimation
								active={true}
								visible={doorAnimation}
								scene={scene}
								itemProps={{
									name: 'door-frame',
									className: 'home__door-frame',
									url: 'images/sprites/frame.png',
									frames: 16,
									clock: 60,
									duration: 0,
									interval: 0,
								}}
								clicked={() => {}}
							/>
							<SpriteAnimation
								active={true}
								visible={doorAnimation}
								scene={scene}
								itemProps={{
									name: 'door-key',
									className: 'home__door-key',
									url: 'images/sprites/key.png',
									frames: 16,
									clock: 60,
									duration: 0,
									interval: 0,
								}}
								clicked={() => {}}
							/>
						</div>

						<img
							className="home__lights"
							src="images/lights.png"
							alt="lights"
							ref={lightsRef}
						/>

						<img
							className="home__light-left"
							src="images/light_left.png"
							alt="light-left"
							ref={lightLeftRef}
						/>

						<img
							className="home__light-right"
							src="images/light_right.png"
							alt="light-right"
							ref={lightRightRef}
						/>

						<div className="home__loading" ref={loadingRef} />

						<audio ref={soundInfoRef}>
							<source type="audio/mp3" src={getSound('ui-open')} />
						</audio>

						<audio ref={soundDoorRef}>
							<source type="audio/mp3" src={getSound('door-open')} />
						</audio>

						<audio ref={soundDoorHoverRef}>
							<source type="audio/mp3" src={getSound('door-pre-open')} />
						</audio>

						<audio ref={soundCloseRef}>
							<source type="audio/mp3" src={getSound('close')} />
						</audio>

						<audio ref={soundCaseCloseRef}>
							<source type="audio/mp3" src={getSound('case-close')} />
						</audio>

						<audio ref={soundKeysBlockRef}>
							<source type="audio/mp3" src={getSound('sweep-transition')} />
						</audio>
					</section>
				</PlayBox>

				<LoginPopup visible={isLoginPopupActive} setVisible={setIsLoginPopupActive} setIsLoginPopupActive={setIsLoginPopupActive} soundCloseRef={soundCloseRef} />
			</section>

			<section className={isRoomActive ? 'scene-container active' : 'scene-container'} style={{ display: scene === 1 ? 'block' : 'none' }}>

				<FirstRoom scene={scene} changeScene={changeScene} setFlipBookActive={setFlipBookActive} setIsLampActive={setIsLampActive} setIsMachinePopupActive={setIsMachinePopupActive} setIsCasePopupActive={setIsCasePopupActive} setIsDeckPopupActive={setIsDeckPopupActive} soundMusicRef={soundMusicRef} soundDoorRef={soundDoorRef} setIsMusicMuted={setIsMusicMuted} isLoginPopupActive={isLoginPopupActive} setIsLoginPopUpActive={setIsLoginPopupActive}/>

			</section>

			<Info active={infoActive} setActive={setInfoActive} soundCloseRef={soundCloseRef} />

			<FlipBookLaptop active={flipBookActive} setActive={setFlipBookActive} />

			<MachinePopup visible={isMachinePopupActive} setVisible={setIsMachinePopupActive} soundCloseRef={soundCloseRef} />

			<CasePopup setIsCasePopupActive={setIsCasePopupActive} setIsDeckPopupActive={setIsDeckPopupActive} visible={isCasePopupActive} setVisible={setIsCasePopupActive} setCurrentActiveDeck={setCurrentActiveDeck} soundCloseRef={soundCaseCloseRef} deckData={deckData} setDeckData={setDeckData} />

			<DeckPopup setIsCasePopupActive={setIsCasePopupActive} setIsDeckPopupActive={setIsDeckPopupActive} visible={isDeckPopupActive} setVisible={setIsDeckPopupActive} currentActiveDeck={currentActiveDeck} soundCloseRef={soundCloseRef} setCaseConfig={setCaseConfig} deckData={deckData} setDeckData={setDeckData} />

			<ComingSoonTooltip visible={isLampActive} setVisible={setIsLampActive}/>
		</>
	);
};

export default Home;