import { soundsResources } from '../constants/main';

export const isMobileSize = screenSize => {
	if (!screenSize) {
		return null;
	}

	return screenSize.width < screenSize.height;
};

export const pictureSettings = (imageSize, { width, height }) => {
	const imageWidth = imageSize.width;
	const imageHeight = imageSize.height;
	
	const imageK = imageWidth / imageHeight;
	const screenK = width / height;
	
	let style = null;
	
	if (screenK < imageK) {
		style = {
			width: '100%',
			height: 'auto'
		}
	} else {
		style = {
			width: 'auto',
			height: '100%'
		}
	}
	
	return style;
};

export const getSounds = (soundsName) => {
	const res = soundsName.reduce((acc, name) => acc.concat(soundsResources.find(sRItem => sRItem.name === name)), []);
	
	return res.filter(item => item !== undefined);
};

export const getSound = (soundName) => {
	const res = soundsResources.find(sRItem => sRItem.name === soundName);
	return res !== undefined ? res.url : undefined;
};