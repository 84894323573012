import React from "react";
import './mobile_plug.css';
import {mobile_plug_title,
        message,
        lower_text,
        telegram,
        twitter,
        youtube,
        planet,
		mobile_plug_back,
} from '../../../constants/mobile-plug';

const MobilePlug = () => {
    
    return (<>
        <div className="mobile-plug-back">
		
            <img
				className={'mobile-title-img'}
				src={mobile_plug_title}
				alt={mobile_plug_title}
				
			/>
            <img
				className={'mobile-message-img'}
				src={message}
				alt={message}
				
			/>
            <div className={'mobile-lowertext-img'}>
			Ziggymon always lurk in the dark<br/>

			Find them on Telegram, Twitter, Youtube and Medium

			</div>
           <a target='_blank' href="https://t.me/ziggyverse">
            <img
				className={'mobile-telegram-img'}
				src={telegram}
				alt={telegram}
                
				
			/>
            </a>
            <a target='_blank' href="https://twitter.com/ziggyverse">
            <img
				className={'mobile-twitter-img'}
				src={twitter}
				alt={twitter}
				
			/>
            </a>
			<a target='_blank' href='https://www.youtube.com/channel/UCd86JJ2lewUay2uw8PuFfiQ/featured'>
            <img
				className={'mobile-youtube-img'}
				src={youtube}
				alt={youtube}
				
			/>
			</a>
            <a target='_blank' href="https://medium.com/ziggyverse">
            <img
				className={'mobile-planet-img'}
				src={planet}
				alt={planet}
				
			/>
            </a>

        </div>

    </>)
};
export default MobilePlug;