import './case_cutting.css'
import {
    emptyCase,
    loadingCase, 
    btn_close,
    ziggymon_ready,
    ziggymon_loading,
    intelligence_active,
    intelligence_not_active,
    armour_active,
    armour_not_active,
    dexterity_active,
    dexterity_not_active,
    energy_active,
    energy_not_active,
    strength_active,
    strength_not_active,
    agility_active,
    agility_not_active,
} from "../../constants/case-cutting";
import {screenContext} from '../../constants/screen-context';
import {useContext} from "react";
import PlayBox from "../_commonComponents/PlayBox/PlayBox";
import { useState, useEffect } from 'react';
import corner from '../../img/corner.png';
import activecard from '../../img/frame active.png';
import frame from '../../img/frame.png';
import image from '../../img/image.png'
import SpriteAnimation from '../_commonComponents/SpriteAnimation/SpriteAnimation';
import axios from 'axios';

import { retriveAddress } from '../../utils/utils';

const CasePopup = ({ visible, setVisible, setIsCasePopupActive, setIsDeckPopupActive, setCurrentActiveDeck, soundCloseRef, deckData, setDeckData}) => {

    const {screenSize} = useContext(screenContext);
    const [nfts, setNfts] = useState([]);
    const [spriteActive, setSpriteActive] = useState(false);
    const [cardIsError, setCardIsError] = useState([false, false, false, false, false, false]);

    const getCountByType = (type) => {
        return nfts.filter((nft) => { return JSON.parse(nft.metadata)?.attributes[0]?.value === type }).length || 0;
    }

    const filterByContract = (nfts) => {
        return nfts.filter((item) => { return item?.token_address ===  "0x353ec0951d8fa71903c5d26236ffc1d5664be9de" });
    }
   
    useEffect( () => {
        const retriveNfts = async () => {
            if (typeof retriveAddress() === "string") {
                const options = {method: 'GET', headers: {Accept: 'application/json', 'X-API-Key': '2g3lyeEx3MfXw6i5kY9eQXW5uphOKZpWetJUZHjaFnJPNpEKXwmiMmauHRVmWFbU'}};
                await axios.get(`https://deep-index.moralis.io/api/v2/${retriveAddress()}/nft?chain=bsc&format=decimal`, options).then(response => {
                    setNfts(filterByContract(response.data.result));
                });
            }
        }

        try {
            retriveNfts();
        } catch(e) {
            console.log(e);
        }
    }, [visible]);

    useEffect(() => {
        document.addEventListener("keydown", function(event) {
            if (event.keyCode === 27) {
                soundCloseRef.current.play();
                setVisible(false);
            };
        });

        setDeckData(JSON.parse(localStorage.getItem('DeckData')) || {});
    },[]);

    useEffect(()=>{
        if(
        getCountByType('Armour')>= 1 &&
        getCountByType('Agility')>= 1 &&
        getCountByType('Strength')>= 1 &&
        getCountByType('Intelligence')>= 1 &&
        getCountByType('Dexterity')>= 1 &&
        getCountByType('Energy')>= 1
        ) { setSpriteActive(true);}
         else{ 
            setSpriteActive(false);
            
        }
    },[visible])

    const getTopImageByType = (type) => {
        const filtered = nfts.filter((nft) => { return JSON.parse(nft.metadata)?.attributes[0]?.value === type });
        if (filtered.length >= 1) {
            let img;
            if (deckData[retriveAddress()]) {
                filtered.map(item => {
                    if (item.token_id === deckData[retriveAddress()][type]) {
                        img = 'https://ipfs.io/ipfs/' + JSON.parse(item.metadata)?.image.slice(7);
                    }
                });
            }
            return img ? img : 'https://ipfs.io/ipfs/' + JSON.parse(filtered[0]?.metadata)?.image.slice(7);
        } else {
            return "";
        }
    }

    return (
        <div 
            style={
              visible
                ? {display: 'block', position: 'absolute', zIndex: '20000', width: '100%',height: '100%', top: '0',left: '0'}
                : { display: 'none' }
            }
          >
        <PlayBox
                screenSize={screenSize}
            >
           
            <div className={'case-home'}>
            <SpriteAnimation
						active={spriteActive}
						visible={spriteActive}
						scene={1}
						itemProps={{
							name: 'case_frame',
							className: 'case_popup__case_frame',
							url: 'images/sprites/Case_frame.png',
							frames: 25,
							clock: 40,
							duration: 0,
							interval: 0,
						}}
						
					/>
                <img className={'case-home-img'}
                     src={spriteActive === true ? emptyCase : loadingCase}
                     alt="empty-case"
                />
                <a onClick={() => 
                    {
                        if (getCountByType("Armour") >= 1) {
                            setCurrentActiveDeck("Armour");
                            setIsCasePopupActive(false);
                            setIsDeckPopupActive(true);
                        }
                    }}
                    >
                       {getCountByType("Armour") >= 1 ? (<>
                        <div className="card_frame_05">

                    <img className={'card_frame_img'}
                         src={activecard}
                         alt="card_05"     
                    />
                    
                </div>
                <div className="card_frame_05_in" >
                    <img className={'card_frame_img' }
                         src={frame}
                         alt="card_05"     
                    />

                    
                </div></>) : '' }
                <div className="card_05">
               

                {
                        getCountByType("Armour") >= 1
                        ? (
                            <>
                                <img className={'card_img'}
                                    src={getTopImageByType("Armour")}
                                    alt=""
                                     onError={() => {setCardIsError({...cardIsError, 4: true})}}
                                />
                                <img className={cardIsError[4] ? 'card_add_img active' : 'card_add_img'} src={image} alt="card_05" />
                            </>
                    )
                            : ''
                    }
                    <div className="content">
                    </div>
                    
                </div>


                <h4 className='card_level5'>{ getCountByType("Armour") }</h4>
                <img className={'hexacon_05'} src={corner} alt={"corner"}/>
                <img className={'armour_text'} src={getCountByType("Armour") >= 1 ? armour_active : armour_not_active} alt={"corner"}/>

                </a>
                <a onClick={() =>
                    {
                        if (getCountByType("Agility") >= 1) {
                            setCurrentActiveDeck("Agility");
                            setIsCasePopupActive(false);
                            setIsDeckPopupActive(true);
                        }
                    }}>

               {getCountByType("Agility") >= 1 ? (<>
                <div className="card_frame_04" >
                    <img className={'card_frame_img' }
                         src={activecard}
                         alt="card_04"

                    />
                </div>
                <div className="card_frame_04_in" >
                    <img className={'card_frame_img' }
                         src={frame}
                         alt="card_04"     
                    />
                    
                </div>

               </>) :
                ''}
                <div className="card_04" >
                    {
                        getCountByType("Agility") >= 1
                        ? (
                            <>
                                <img className={'card_img' }
                                    src={getTopImageByType("Agility")}
                                    alt=""
                                     onError={() => {setCardIsError({...cardIsError, 3: true})}}
                                />
                                <img className={cardIsError[3] ? 'card_add_img active' : 'card_add_img'} src={image} alt="card_04" />
                            </>
                        )
                            : ''
                    }
                    <div className="content" >
                    </div>
                </div>

                    <h4 className={`card_level4`}>{ getCountByType("Agility") }</h4>
                    <img className={`hexacon_04`} src={corner} alt={"corner"}/> 
                    <img className={'agility_text'} src={getCountByType("Agility") >= 1 ? agility_active : agility_not_active} alt={"corner"}/>
                </a>

                

                <a onClick={() =>
                    {
                        if (getCountByType("Strength") >= 1) {
                            setCurrentActiveDeck("Strength");

                            setIsCasePopupActive(false);
                            setIsDeckPopupActive(true);
                        }
                    }}>
               {getCountByType("Strength")>=1 ? (<><div className="card_frame_03" >
                    <img className={'card_frame_img' }
                         src={activecard}
                         alt="card_03"
                />
                    
                </div>
                <div className="card_frame_03_in" >
                   <img className={'card_frame_img' }
                         src={frame}
                         alt="card_03"     
                    />


                    
                </div></>) : ''}

                <div className="card_03" >
                    {
                        getCountByType("Strength") >= 1

                        ? (
                            <>
                                <img className={'card_img'}
                                    src={ getTopImageByType("Strength") }
                                    alt=""
                                     onError={() => {setCardIsError({...cardIsError, 2: true})}}
                                />
                                <img className={cardIsError[2] ? 'card_add_img active' : 'card_add_img'} src={image} alt="card_03" />
                            </>
                        ) : ''
                    }
                    <div className="content">
                    </div>
                </div>
                <h4 className='card_level3'>{getCountByType("Strength")}</h4>
                <img className={'hexacon_03'} src={corner} alt={"corner"}/>


                <img className={'strength_text'} src={getCountByType("Strength") >= 1 ? strength_active : strength_not_active} alt={"corner"}/>


                </a>
                <a onClick={() =>
                    {
                        if (getCountByType("Intelligence") >= 1) {
                            setCurrentActiveDeck("Intelligence");
                            setIsCasePopupActive(false);
                            setIsDeckPopupActive(true);
                        }
                    }}>
                {getCountByType("Intelligence") >= 1 ?(<><div className="card_frame_02" >
                    <img className={'card_frame_img' }
                         src={activecard}
                         alt="card_02"
                    />
                    
                </div>
                <div className="card_frame_02_in" >
                    <img className={'card_frame_img' }
                         src={frame}
                         alt="card_02"     
                    />
                    
                </div></>) : ''}
                <div className="card_02" >
                {
                        getCountByType("Intelligence") >= 1
                        ? (
                            <>
                                <img className={'card_img' }
                                    src={getTopImageByType("Intelligence")}
                                    alt=""
                                     onError={() => {setCardIsError({...cardIsError, 1: true})}}
                                />
                                <img className={cardIsError[1] ? 'card_add_img active' : 'card_add_img'} src={image} alt="card_02" />
                            </>
                        )
                            : ''
                    }
                    <div className="content">
                    </div>
                </div>
                </a>


                <a onClick={() =>
                    {
                        if (getCountByType("Dexterity") >= 1) {
                            setCurrentActiveDeck("Dexterity");
                            setIsCasePopupActive(false);
                            setIsDeckPopupActive(true);
                        }
                    }}>
                {getCountByType("Dexterity") >=1 ? (<><div className="card_frame_01" >
                    <img className={'card_frame_img' }
                         src={activecard}
                         alt="card_01"
                    />
                    
                </div>
                <div className="card_frame_01_in" >
                    <img className={'card_frame_img' }
                         src={frame}
                         alt="card_01"     
                    />
                    
                </div></>) : ''}
                
                <h4 className='card_level2'>{ getCountByType("Intelligence") }</h4>
                <img className={'hexacon_02'} src={corner} alt={"corner"}/>
                <img className={'intelligence_text'} src={getCountByType("Intelligence") >= 1 ? intelligence_active : intelligence_not_active} alt={"corner"}/>
                <div className="card_01">
                {
                        getCountByType("Dexterity") >= 1
                        ? (
                            <>
                                <img className={'card_img' }
                                    src={getTopImageByType("Dexterity")}
                                    alt=""
                                     onError={() => {setCardIsError({...cardIsError, 0: true})}}
                                />
                                <img className={cardIsError[0] ? 'card_add_img active' : 'card_add_img'} src={image} alt="card_01" />
                            </>
                        )
                            : ''
                    }
               
                    <div className="content">
                    </div>
                </div>
                </a>


                <h4 className={'card_level1'}>{ getCountByType("Dexterity") }</h4>
                <img className={'hexacon_01'} src={corner} alt={"corner"}/>


                <img className={'dexterity_text'} src={getCountByType("Dexterity") >= 1 ? dexterity_active : dexterity_not_active} alt={"corner"}/>


                <a onClick={() =>
                    {
                    setCurrentActiveDeck("Energy");    
                    setIsCasePopupActive(false);
                    setIsDeckPopupActive(true);
                    }}>



                {getCountByType("Energy") >=1 ? (<><div className={'card_frame_06'} >
                    <img className={'card_frame_img' }
                         src={activecard}
                         alt="card_06"

                    />
                    
                </div>

 <div className={"card_frame_06_in"} >


                    <img className={'card_frame_img' }
                         src={frame}
                         alt="card_06"     
                    />

                    
                </div></>) : ''}
                <div className= {"card_06"}>
                {

                        getCountByType("Energy") >= 1
                        ? (
                            <>
                                <img className={'card_img' }
                                    src={getTopImageByType("Energy")}
                                    alt=""
                                     onError={() => {setCardIsError({...cardIsError, 5: true})}}
                                />
                                <img className={cardIsError[5] ? 'card_add_img active' : 'card_add_img'} src={image} alt="card_06" />
                            </>
                        )
                            : ''
                    }
                    <div className="content">
                    </div>
                </div>

               
                <img className={'hexacon_06'} src={corner} alt={"corner"}/>
                <img className={'energy_text'} src={getCountByType("Energy") >= 1 ? energy_active : energy_not_active} alt={"corner"}/>
                <h4 className='card_level6'>{ getCountByType("Energy") }</h4>
              
                </a>

                <h4 className='card_level11'/>
                <img className={'hexacon_05'} src={corner} alt={"corner"}/>

				<a onClick={() => {
                    soundCloseRef.current.play();
                    setVisible(false);
                }}>
					<img className='btn_close' src={btn_close} alt="btn_close"/>
				</a>				

				<img className={spriteActive === true ? "ziggymon_ready" : 'ziggymon_loading'} src={spriteActive === true ? ziggymon_ready : ziggymon_loading} alt={"ziggymon_ready"}/>

            </div>
        </PlayBox>
        </div>
        );
};

export default CasePopup;