import React from "react";
import { Popover } from 'antd';
import './case_cutting_popup.css';

import {screenContext} from '../../constants/screen-context';
import {useContext} from "react";
import replace_btn from '../../img/btn replace.png';
import { useState, useEffect } from "react";
import Card from './casecard';

import axios from 'axios';
import { retriveAddress } from "../../utils/utils";

const DeckPopup = ({ visible, setVisible, setIsCasePopupActive, setIsDeckPopupActive, currentActiveDeck, soundCloseRef, setCaseConfig, deckData, setDeckData }) => {

    const {screenSize} = useContext(screenContext);
    const [ currentActive, setCurrentActive ] = useState(null);

    const [nfts, setNfts] = useState([]);

    useEffect(() => {
        if (!visible) {
            setCurrentActive(null);
        }
    }, [visible]);

    useEffect(() => {
        document.addEventListener("keydown", function(event) {
           
            if (event.keyCode ===27) {
                soundCloseRef.current.play();
                    setIsDeckPopupActive(false);
            };
           
          })},[]);

    const filterByContract = (nfts) => {
        return nfts.filter((item) => { return item?.token_address ===  "0x353ec0951d8fa71903c5d26236ffc1d5664be9de" });
    }
    
    useEffect( () => {
        const retriveNfts = async () => {

            if (typeof retriveAddress() === "string") {
                const options = {method: 'GET', headers: {Accept: 'application/json', 'X-API-Key': '2g3lyeEx3MfXw6i5kY9eQXW5uphOKZpWetJUZHjaFnJPNpEKXwmiMmauHRVmWFbU'}};
                await axios.get(`https://deep-index.moralis.io/api/v2/${retriveAddress()}/nft?chain=bsc&format=decimal`, options).then(response => {

                if (currentActiveDeck !== null) {
                    setNfts(filterByContract(response.data.result.filter((nft) => { return Array.isArray(JSON.parse(nft.metadata)?.attributes) && JSON.parse(nft.metadata)?.attributes[0]?.value === currentActiveDeck })));
                }
            })
            }
        }

        try {
            retriveNfts();
            /*window.deckInterval = setInterval(() => {
                retriveNfts();
            }, 3000);*/
        } catch(e) {

        }

        /*return () => {
            clearInterval(window.deckInterval);
        }*/
    }, [currentActiveDeck]);

    useEffect( () => {
        localStorage.setItem('DeckData', JSON.stringify(deckData));
    }, [deckData]);

    const replace = () => {
        let el0, elActive, data;
        if (nfts[0] && nfts[currentActive] && currentActive) {
            el0 = nfts[0];
            elActive = nfts[currentActive];
            setNfts([...nfts].map((el, index) => {
                if (index === 0) {
                    return elActive;
                } else if (index === currentActive) {
                    return el0;
                } else return el;
            }));

            setDeckData({...deckData, [retriveAddress()]: {...deckData[retriveAddress()], [currentActiveDeck]: elActive.token_id}})
        }
    }

    return (
        <div
            style={
              visible
                ? {display: 'block', position: 'absolute', zIndex: '20000', width: '100%',height: '100%', top: '0',left: '0'}
                : { display: 'none' }
            }
          >
        <section className="body_deck_color">
        <section className="body_deck">
            <div className="container_info_">
                <div className="top_info_deck">
                    <div className="top_info_deck_title">
                        DECK
                    </div>
                    <a
                        className="top_info_deck_close"
                        onClick={() =>
                            {
                                soundCloseRef.current.play();
                                setIsCasePopupActive(true);
                                setIsDeckPopupActive(false);
                            }}

                    />
                </div>
                <div className="page-content">
                    <div className="cards-content">

                        {nfts?.map((item, index) => (

                            <Card
                                key={index}
                                type={JSON.parse(item.metadata)?.attributes[0]?.value}
                                maretial={JSON.parse(item.metadata)?.attributes[1]?.value}
                                element={JSON.parse(item.metadata)?.attributes[2]?.value}
                                level={JSON.parse(item.metadata)?.attributes[3]?.value}
                                durability={JSON.parse(item.metadata)?.attributes[4]?.value}
                                currentActive={currentActive}
                                setCurrentActive={setCurrentActive}
                                image={"https://ipfs.io/ipfs/" + JSON.parse(item.metadata)?.image.slice(7)}
                                index={item.key || index}
                                setConfig={setCaseConfig}
                                name={JSON.parse(item.metadata)?.name}

                            />
                        ))}
                    </div>
                    <div className="replace-btn" onClick={replace}>
                        <img className={'replace-btn-img'}
                            src={replace_btn}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>
        </section>
        </div>
    )
};
export default DeckPopup;