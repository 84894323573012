import { useState, memo } from 'react';

const AnimatedImage = ({ itemProps, clicked }) => {
	const {
		name,
		className,
		alt,
		url,
		urlActive,
	} = itemProps;

	const [isActiveShow, setIsActiveShow] = useState(false);

	const mouseEnterHandler = () => {
		if (!urlActive) {
			return;
		}
		
		setIsActiveShow(true);
	};
	
	const mouseEnterLeave = () => {
		if (!urlActive) {
			return;
		}
		
		setIsActiveShow(false);
	};
	
	const clickHandler = () => {
		clicked(name);
	};
	
	return (
		<div className={"animated-image " + className} onClick={clickHandler}>

			<img
				className={isActiveShow ? 'hidden' : ''}
				src={url}
				alt={alt}
				onMouseEnter={mouseEnterHandler}
			/>

			{urlActive &&
			<img
				className={!isActiveShow ? 'hidden' : ''}
				src={urlActive}
				alt={alt}
				onMouseLeave={mouseEnterLeave}
			/>
			}

		</div>		
	);
};

export default memo(AnimatedImage);