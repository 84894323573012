import { useState, useEffect } from 'react';

const useScreenSize = () => {
	const [size, setSize] = useState(null);
	
	useEffect(() => {
		setSize({
				width: window.innerWidth,
				height: window.innerHeight
			})
		
		window.addEventListener('resize', () => {
			setSize({
				width: window.innerWidth,
				height: window.innerHeight
			})
		});
	}, []);
	
	return size;
};

export default useScreenSize;