import {useState, useContext, useRef, useCallback} from 'react';

import PlayBox from '../_commonComponents/PlayBox/PlayBox';
import AnimatedImage from '../_commonComponents/AnimatedImage/AnimatedImage';
import SpriteAnimation from '../_commonComponents/SpriteAnimation/SpriteAnimation';
import { screenContext } from '../../constants/screen-context';

import {
	fonURL,
	fonMobileURL,
	imageList,
	messageURL,
	melodies
} from '../../constants/first-room';
import {
	isMobileSize,
	getSound
} from '../../services/main';
import { retriveAddress } from '../../utils/utils';

const FirstRoom = ({ scene, changeScene, setFlipBookActive, setIsMachinePopupActive, setIsCasePopupActive, setIsLampActive, isLampActive, soundMusicRef, soundDoorRef, setIsMusicMuted, isLoginPopupActive, setIsLoginPopUpActive }) => {

	const { screenSize } = useContext(screenContext);
	const isMobile = isMobileSize(screenSize);
	const [isMessageShow, setIsMessageShow] = useState(false);
	const [isRocketActive, setIsRocketActive] = useState(false);
	const [isPlanet1Active, setPlanet1Active] = useState(false);
	const [isPlanet2Active, setPlanet2Active] = useState(false);
	const [isPlanet3Active, setPlanet3Active] = useState(false);

	const [isPCActive, setPCActive] = useState(false);
	const [isTabletActive, setTabletActive] = useState(false);
	const [isHologramClicked, setIsHologramClicked] = useState(false);
	const [isHologramRevert, setIsHologramRevert] = useState(false);
	const [isHologramActive, setIsHologramActive] = useState(false);
	const [isCaseActive, setIsCaseActive] = useState(false);
	const [isComicsHover, setIsComicsHover] = useState(false);
	const [isComicsActive, setIsComicsActive] = useState(false);
	const [isPlayerAnimationVisible, setIsPlayerAnimationVisible] = useState(false);
	const [isMusicPlaying, setIsMusicPlaying] = useState(true);
	const [musicMelody, setMusicMelody] = useState('spaceport-1');

	const soundClickRef = useRef(null);
	const soundLampRef = useRef(null);
	const soundComicsRef = useRef(null);
	const soundHologramOpenRef = useRef(null);
	const soundHologramCloseRef = useRef(null);
	const soundRocketRef = useRef(null);
	const soundMachineRef = useRef(null);
	const soundPlanet1Ref = useRef(null);
	const soundPlanet2Ref = useRef(null);
	const soundPlanet3Ref = useRef(null);
	const soundUIOpenRef = useRef(null);
	const soundCaseOpenRef = useRef(null);
	const soundTabletRef = useRef(null);

	const handleOpenApp = useCallback(() => {
		window.open('https://app.ziggyverse.com/', '_blank');
	}, []);

	if (isMobile === null) {
		return;
	}

	const changeMusicMelody = () => {
		const melody = melodies[Math.floor(Math.random() * melodies.length)];
		if (melody === musicMelody) {
			changeMusicMelody();
		} else {
			setMusicMelody(melody);
		}
	}

	const musicEnded = () => {
		changeMusicMelody();
		soundMusicRef.current.load();
		soundMusicRef.current.play();
	}

	const imageClickedHandler = name => {
		switch (name) {
			case 'door':
				soundDoorRef.current.play();
				setTimeout(() => {
					changeScene(0);
				}, 300);
				break;
			case 'lamp' :
				soundLampRef.current.play();
				setIsLampActive(true);
				setTimeout(() => {
					setIsLampActive(false);
				}, 1000);
				break;
			case 'comics':
				soundComicsRef.current.play();
				setIsComicsHover(false);
				setIsComicsActive(true);
				setTimeout(() => {
					setIsComicsActive(false);
					setFlipBookActive(true);
				}, 640);
				break;
			case 'player':
				if (isMusicPlaying) {
					soundMusicRef.current.pause();
					setIsMusicPlaying(false);
					setIsMusicMuted(true);
				} else {
					changeMusicMelody();
					soundMusicRef.current.load();
					soundMusicRef.current.play();
					setIsMusicPlaying(true);
				}
				break;
			case 'case':
				if (typeof retriveAddress() === "string") {
					soundCaseOpenRef.current.play();
					setIsCaseActive(true);
					setTimeout(() => {
						setIsCaseActive(false);
						setIsCasePopupActive(true);
					}, 960);
				} else {
					setIsLoginPopUpActive(true);
					changeScene(0);
					//alert("You need to LogIn to continue")
				}
				break;
			case 'pc':
				soundTabletRef.current.play();
				setPCActive(true);
				setTimeout(() => {
					setPCActive(false);
				}, 960);
				break;
			case 'tablet':
				soundTabletRef.current.play();
				setTabletActive(true);
				setTimeout(() => {
					setTabletActive(false);
				}, 960);
				break;
			case 'machine':
				soundMachineRef.current.play();
				setIsMachinePopupActive(true);
				break;
			case 'planet-1':
				soundPlanet1Ref.current.play();
				setPlanet1Active(true);
				setTimeout(() => {
					setPlanet1Active(false);
				}, 920);
				break;
			case 'planet-2':
				soundPlanet2Ref.current.play();
				setPlanet2Active(true);
				setTimeout(() => {
					setPlanet2Active(false);
				}, 920);
				break;
			case 'planet-3':
				soundPlanet3Ref.current.play();
				setPlanet3Active(true);
				setTimeout(() => {
					setPlanet3Active(false);
				}, 920);
				break;
			case 'rocket':
				soundRocketRef.current.play();
				setIsRocketActive(true);
				setTimeout(() => {
					setIsRocketActive(false);
				}, 1000);
				break;
			case 'hologram':
				if (isHologramActive) {
					soundHologramCloseRef.current.play();
					setIsHologramClicked(true);
					setIsHologramRevert(true);
					setTimeout(() => {
						setIsHologramActive(false);
					}, 30);
					setTimeout(() => {
						setIsHologramClicked(false);
					}, 480);
				} else {
					soundHologramOpenRef.current.play();
					setIsHologramClicked(true);
					setIsHologramRevert(false);
					setTimeout(() => {
						setIsHologramClicked(false);
						setIsHologramActive(true);
					}, 480);
				}
				break;
		}
	};

	return (
		<>
			<PlayBox
				screenSize={screenSize}
			>
				<section className={`first-room${isMobile ? ' first-room_mobile' : ''}`}>
					{ !isMobile
					&&
					<img
						className="home__fon"
						src={fonURL}
						alt="first-room"
					/>
					}

					{ isMobile
					&&
					<img
						className="home__fon"
						src={fonMobileURL}
						alt="first-room"
					/>
					}

					{ imageList.map((item, index) => {
						const itemProps = {
							name: item.name,
							className: 'first-room__' + item.name,
							classNameActive: 'first-room__' + item.name +'_active',
							alt: 'first-room-' + item.name,
							url: item.url,
							urlActive: item.urlActive,
							urlAlt: item.urlAlt,
							urlAltActive: item.urlAltActive
						};

						return (
							<AnimatedImage
								key={index}
								itemProps={itemProps}
								clicked={imageClickedHandler}
							/>
						)
					}) }

					{ isMessageShow && (
						<img
							className="first-room__message"
							src={messageURL}
							alt="Coming soon"
						/>
					) }

					<div
						className="first-room__door_container"
						// ref={doorRef}
						// onClick={doorClickedHandler}
						// onMouseEnter={doorMouseEnter}
						// onMouseLeave={doorMouseLeave}
					>
						<div className="first-room__door">

						</div>
					</div>

					<img
						className="first-room__player"
						src="images/first-room/player.png"
						alt="player"
						onMouseEnter={() => {setIsPlayerAnimationVisible(true)}}
					/>

					<SpriteAnimation
						active={isRocketActive}
						visible={true}
						scene={scene}
						itemProps={{
							name: 'rocket',
							className: 'first-room__rocket',
							url: 'images/sprites/rocket.png',
							frames: 25,
							clock: 40,
							duration: 0,
							interval: 0,
						}}
						clicked={() => {}}
					/>

					<div
						className="first-room__rocket-hitbox"
						onClick={() => imageClickedHandler('rocket')}
					/>

					<div onMouseLeave={() => {setIsPlayerAnimationVisible(false)}}>
						<SpriteAnimation
							active={true}
							visible={isPlayerAnimationVisible}
							scene={scene}
							itemProps={{
								name: 'player',
								className: 'first-room__player-sprite',
								url: 'images/sprites/player.png',
								frames: 16,
								clock: 60,
								duration: 0,
								interval: 0,
							}}
							clicked={imageClickedHandler}
						/>
					</div>

					<SpriteAnimation
						active={true}
						visible={isMusicPlaying}
						scene={scene}
						itemProps={{
							name: 'notes',
							className: 'first-room__notes',
							url: 'images/sprites/notes.png',
							frames: 24,
							clock: 60,
							duration: 0,
							interval: 0,
						}}
						clicked={imageClickedHandler}
					/>

					<SpriteAnimation
						active={isPlanet1Active}
						visible={true}
						scene={scene}
						itemProps={{
							name: 'planet-1',
							className: 'first-room__planet-1',
							url: 'images/sprites/planet-1.png',
							frames: 23,
							clock: 40,
							duration: 0,
							interval: 0,
						}}
						clicked={imageClickedHandler}
					/>

					<SpriteAnimation
						active={isPlanet2Active}
						visible={true}
						scene={scene}
						itemProps={{
							name: 'planet-2',
							className: 'first-room__planet-2',
							url: 'images/sprites/planet-2.png',
							frames: 23,
							clock: 40,
							duration: 0,
							interval: 0,
						}}
						clicked={imageClickedHandler}
					/>

					<SpriteAnimation
						active={isPlanet3Active}
						visible={true}
						scene={scene}
						itemProps={{
							name: 'planet-3',
							className: 'first-room__planet-3',
							url: 'images/sprites/planet-3.png',
							frames: 23,
							clock: 40,
							duration: 0,
							interval: 0,
						}}
						clicked={imageClickedHandler}
					/>

					<SpriteAnimation
						active={true}
						visible={isHologramActive}
						scene={scene}
						itemProps={{
							name: 'hologram',
							className: 'first-room__hologram-sprite',
							url: 'images/sprites/hologram.png',
							frames: 16,
							clock: 60,
							duration: 0,
							interval: 0,
						}}
						clicked={imageClickedHandler}
					/>

					<SpriteAnimation
						active={isHologramClicked}
						visible={true}
						scene={scene}
						itemProps={{
							name: 'hologram',
							className: 'first-room__hologram-sprite',
							url: 'images/sprites/hologram_click.png',
							frames: 16,
							clock: 30,
							duration: 0,
							interval: 0,
						}}
						clicked={imageClickedHandler}
						revert={isHologramRevert}
					/>

					{isHologramActive ? <div className="first-room__hologram-sprite_beam" onClick={handleOpenApp}/> : null}

					<img
						className="first-room__comics"
						src="images/first-room/comics.png"
						alt="comics"
						onMouseEnter={() => {setIsComicsHover(true)}}
						onMouseLeave={() => {setIsComicsHover(false)}}
						onClick={() => imageClickedHandler('comics')}
					/>

					<SpriteAnimation
						active={isComicsHover}
						visible={isComicsHover}
						scene={scene}
						itemProps={{
							name: 'comics',
							className: 'first-room__comics-sprite',
							url: 'images/sprites/comics.png',
							frames: 8,
							clock: 80,
							duration: 0,
							interval: 0,
							stopAtEnd: true,
						}}
						clicked={imageClickedHandler}
					/>

					<SpriteAnimation
						active={isComicsActive}
						visible={isComicsActive}
						scene={scene}
						itemProps={{
							name: 'comics',
							className: 'first-room__comics-sprite',
							url: 'images/sprites/comics_click.png',
							frames: 8,
							clock: 80,
							duration: 0,
							interval: 0,
						}}
						clicked={imageClickedHandler}
					/>

					<SpriteAnimation
						active={true}
						visible={true}
						scene={scene}
						itemProps={{
							name: 'tablet',
							className: 'first-room__tablet-sprite',
							url: 'images/sprites/tablet.png',
							frames: 16,
							clock: 80,
							duration: 0,
							interval: 0,
							hover: true,
						}}
						clicked={imageClickedHandler}
					/>

					<SpriteAnimation
						active={true}
						visible={isTabletActive}
						scene={scene}
						itemProps={{
							name: 'tablet',
							className: 'first-room__tablet-sprite',
							url: 'images/sprites/tablet_click.png',
							frames: 16,
							clock: 60,
							duration: 0,
							interval: 0
						}}
						clicked={imageClickedHandler}
					/>

					<SpriteAnimation
						active={true}
						visible={true}
						scene={scene}
						itemProps={{
							name: 'pc',
							className: 'first-room__pc-sprite',
							url: 'images/sprites/pc.png',
							frames: 25,
							clock: 80,
							duration: 0,
							interval: 0,
							hover: true,
						}}
						clicked={imageClickedHandler}
					/>

					<SpriteAnimation
						active={true}
						visible={isPCActive}
						scene={scene}
						itemProps={{
							name: 'pc',
							className: 'first-room__pc-sprite',
							url: 'images/sprites/pc_click.png',
							frames: 16,
							clock: 80,
							duration: 0,
							interval: 0,
						}}
						clicked={imageClickedHandler}
					/>

					<SpriteAnimation
						active={true}
						visible={true}
						scene={scene}
						itemProps={{
							name: 'case',
							className: 'first-room__nft',
							url: 'images/sprites/nft.png',
							frames: 16,
							clock: 60,
							duration: 0,
							interval: 0,
							hover: true,
						}}
						clicked={imageClickedHandler}
					/>

					<SpriteAnimation
						active={isCaseActive}
						visible={isCaseActive}
						scene={scene}
						itemProps={{
							name: 'case',
							className: 'first-room__case-sprite',
							url: 'images/sprites/case.png',
							frames: 16,
							clock: 60,
							duration: 0,
							interval: 0,
						}}
						clicked={imageClickedHandler}
					/>

					<SpriteAnimation
						active={true}
						visible={true}
						scene={scene}
						itemProps={{
							name: 'bookshelf',
							className: 'first-room__window-sprite',
							url: 'images/sprites/Window.png',
							frames: 16,
							clock: 60,
							duration: 0,
							interval: 0,
						}}

					/>

					<audio ref={soundClickRef}>
						<source type="audio/mp3" src={getSound('click')} />
					</audio>

					<audio ref={soundLampRef}>
						<source type="audio/mp3" src={getSound('lamp')} />
					</audio>

					<audio ref={soundComicsRef}>
						<source type="audio/mp3" src={getSound('comics-open')} />
					</audio>

					<audio ref={soundHologramOpenRef}>
						<source type="audio/mp3" src={getSound('hologram-open')} />
					</audio>

					<audio ref={soundHologramCloseRef}>
						<source type="audio/mp3" src={getSound('hologram-close')} />
					</audio>

					<audio ref={soundRocketRef}>
						<source type="audio/mp3" src={getSound('rocket')} />
					</audio>

					<audio ref={soundMachineRef}>
						<source type="audio/mp3" src={getSound('machine')} />
					</audio>

					<audio ref={soundPlanet1Ref}>
						<source type="audio/mp3" src={getSound('planet-1')} />
					</audio>

					<audio ref={soundPlanet2Ref}>
						<source type="audio/mp3" src={getSound('planet-2')} />
					</audio>

					<audio ref={soundPlanet3Ref}>
						<source type="audio/mp3" src={getSound('planet-3')} />
					</audio>

					<audio ref={soundUIOpenRef}>
						<source type="audio/mp3" src={getSound('ui-open')} />
					</audio>

					<audio ref={soundCaseOpenRef}>
						<source type="audio/mp3" src={getSound('case-open')} />
					</audio>

					<audio ref={soundTabletRef}>
						<source type="audio/mp3" src={getSound('tablet')} />
					</audio>

					<audio ref={soundMusicRef} onEnded={musicEnded}>
						<source type="audio/mp3" src={getSound(musicMelody)} />
					</audio>

				</section>
			</PlayBox>
		</>
	);
};

export default FirstRoom;