import { useState, useRef, useEffect } from 'react';
import { screenContext } from './constants/screen-context';
import useScreenSize from './services/screenSizeHook';
import Home from './components/Home/Home';
import MobilePlug from '../src/components/_commonComponents/MobilePlug/MobilePlug';

function App() {
	const screenSize = useScreenSize();
	const appRef = useRef();


	
			if (window.screen.width < 992) {
				return (<MobilePlug/>);
			} else {
		return (
			<section
				className="App"
				ref={appRef}
			>
				<screenContext.Provider
					value={{
						screenSize,
						appRef
					}}
				>
					<Home />
				</screenContext.Provider>
			</section>
	);};
};

export default App;