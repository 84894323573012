export const emptyCase='images/case-cutting/Empty case.png';
export const loadingCase='images/case-cutting/Empty case loading.png';

export const card_01='images/case-cutting/card_01.png';
export const card_02='images/case-cutting/card_02.png';
export const card_03='images/case-cutting/card_03.png';
export const card_04='images/case-cutting/card_04.png';
export const card_05='images/case-cutting/card_05.png';
export const card_06='images/case-cutting/card_06.png';

export const btn_close="images/case-cutting/btn close.png";
export const hexacon = 'images/case-cutting/hexacon.png';

export const ziggymon_ready= 'images/case-cutting/ZIGGYMON READY!.png';
export const ziggymon_loading= 'images/case-cutting/ZIGGYMON LOADING.png';

export const deck_home = 'images/case-cutting/deck_home.png';
export const card_active = 'images/case-cutting/card_active.png';

export const agility_active = 'images/case-cutting/card status/AGILITY(active).png';
export const agility_not_active = 'images/case-cutting/card status/AGILITY.png';
export const armour_active = 'images/case-cutting/card status/ARMOUR(active).png';
export const armour_not_active = 'images/case-cutting/card status/ARMOUR.png';
export const dexterity_active = 'images/case-cutting/card status/DEXTERITY(active).png';
export const dexterity_not_active = 'images/case-cutting/card status/DEXTERITY.png';
export const energy_active = 'images/case-cutting/card status/ENERGY(active).png';
export const energy_not_active = 'images/case-cutting/card status//ENERGY.png';
export const intelligence_active = 'images/case-cutting/card status/INTELLIGENCE(active).png';
export const intelligence_not_active = 'images/case-cutting/card status/INTELLIGENCE.png';
export const strength_active = 'images/case-cutting/card status/STRENGTH(active).png';
export const strength_not_active = 'images/case-cutting/card status/STRENGTH.png';