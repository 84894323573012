export const fonURL = 'images/comics/background.png';
export const logoURL = 'images/comics/COMICS.png';
export const btn_close = 'images/comics/btn close_1.png';
export const selected_ep = 'images/comics/nav_link_select.png'

export const clipList = [
	{
		name: 'Episode 1',
		url: '#'
	},
	{
		name: 'Episode 2',
		url: '#'
	},
	{
		name: 'Episode 3',
		url: '#'
	},
	{
		name: 'Episode 4',
		url: '#'
	},
	{
		name: 'Episode 5',
		url: '#'
	}
];

export const comicsList = [
	'images/comics/one.jpg',
	'images/comics/two.jpg',
	'images/comics/two.jpg',
	'images/comics/two.jpg',
	'images/comics/two.jpg',
	'images/comics/two.jpg',
	'images/comics/two.jpg',
	'images/comics/two.jpg'
];