export const imageList = [
	// {
	// 	name: 'door',
	// 	url: 'images/door.png',
	// 	urlActive: 'images/door_active.png'
	// },
	// {
	// 	name: 'name',
	// 	url: 'images/name.png',
	// 	urlActive: null
	// },
	// {
	// 	name: 'info',
	// 	url: 'images/info.png',
	// 	urlActive: 'images/info_active.png'
	// },
	// {
	// 	name: 'login',
	// 	url: 'images/login.png',
	// 	urlActive: 'images/login_active.png'
	// },
	// {
	// 	name: 'lights',
	// 	url: 'images/lights.png',
	// 	urlActive: null
	// },
	// {
	// 	name: 'light-left',
	// 	url: 'images/light_left.png',
	// 	urlActive: null
	// },
	// {
	// 	name: 'light-right',
	// 	url: 'images/light_right.png',
	// 	urlActive: null
	// },
	// {
	// 	name: 'ray-left',
	// 	url: 'images/ray.png',
	// 	urlActive: null
	// },
	// {
	// 	name: 'ray-right',
	// 	url: 'images/ray.png',
	// 	urlActive: null
	// },
	// {
	// 	name: 'ray-central',
	// 	url: 'images/ray_central.png',
	// 	urlActive: null
	// }
];

export const spriteList = [
	{
		name: 'info',
		url: 'images/sprites/button.png',
		frames: 16,
		clock: 60,
		duration: 0,
		interval: 0
	},
	{
		name: 'info',
		url: 'images/sprites/info.png',
		frames: 30,
		clock: 60,
		duration: 0,
		interval: 0
	},
	{
		name: 'info',
		url: 'images/sprites/sweep.png',
		frames: 16,
		clock: 40,
		duration: 640,
		interval: 10000
	},
	{
		name: 'login',
		url: 'images/sprites/button.png',
		frames: 16,
		clock: 60,
		duration: 0,
		interval: 0
	},
	{
		name: 'login',
		url: 'images/sprites/login.png',
		frames: 30,
		clock: 60,
		duration: 0,
		interval: 0
	},
	{
		name: 'login',
		url: 'images/sprites/sweep.png',
		frames: 16,
		clock: 40,
		duration: 640,
		interval: 10000
	},
	{
		name: 'ray-central',
		url: 'images/sprites/ray_central.png',
		frames: 16,
		clock: 40,
		duration: 0,
		interval: 0
	},
	{
		name: 'ray-left',
		url: 'images/sprites/ray.png',
		frames: 16,
		clock: 40,
		duration: 0,
		interval: 0
	},
	{
		name: 'ray-right',
		url: 'images/sprites/ray.png',
		frames: 16,
		clock: 40,
		duration: 0,
		interval: 0
	},
];

export const fonURL = 'images/home-page.png';
export const fonMobileURL = 'images/home-page-mobile.png';