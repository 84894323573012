import React from "react";
import {screenContext} from '../../../constants/screen-context';
import {useContext, useEffect, useState} from "react";
import './machine_popup.css';
import bundles_text from '../../../img/machine/BUNDLES.png';
import bundleback from '../../../img/machine/bundlesback.png';
import comingsoon from '../../../img/machine/coming soon.png';
import 'animate.css';
import SpriteAnimation from '../../_commonComponents/SpriteAnimation/SpriteAnimation';

const MachinePopup = ({ visible, setVisible, soundCloseRef }) => {
    const {screenSize} = useContext(screenContext);
    const [isCardsVisible, setIsCardVisible] = useState(false);
    useEffect(() => {
        if(visible === true) {
            setTimeout(() => {
                setIsCardVisible(true);
            }, 900)}
        else {
            setIsCardVisible(false);
        }
    },[visible]);
    useEffect(() => {
    document.addEventListener("keydown", function(event) {
        if (event.keyCode === 27) {
            soundCloseRef.current.play();
            setVisible(false);
        };
      })},[]);
    return (
        <>
            <div
                style={
                    visible
                        ? { display: 'block', position: 'absolute' }
                        : { display: 'none' }
                }
            >

                <div className="background_fon">
                    <div className="top_info_login">
                        <div className="title animate__animated animate__bounceInDown ">
                            TILESHOP
                        </div>
                        <SpriteAnimation
						active={isCardsVisible}
						visible={isCardsVisible}
						scene={1}
						itemProps={{
							name: 'tileshopcards',
							className: 'back_cards_img',
							url: 'images/sprites/Cards.png',
							frames: 22,
							clock: 40,
							duration: 0,
							interval: 0,
                            stopAtEnd: true,

						}}
						
					/>
                        <a
                            className="close animate__animated animate__bounceInDown "
                            onClick={() => {
                                soundCloseRef.current.play();
                                setVisible(false);
                            }}
                        />
                    </div>
                    <div className="tileshop_content">
                        <div className="tileshop-content-header animate__animated animate__bounceIn animate__slow animate__delay-2s" >
                        <img
                                className="tileshop-header-text"
                                src={bundles_text}
                                alt='bundles_text'/>
                        <SpriteAnimation
                            active={isCardsVisible}
                            visible={isCardsVisible}
                            scene={1}
                            itemProps={{
							name: 'loadingback',
							className: 'tileshop-content-img',
							url: 'images/sprites/Loading.png',
							frames: 15,
							clock: 40,
							duration: 0,
							interval: 0,
						}}
						
					/>
                        </div>
                      


                        <div className="back_cards">
                        
                            <img
                            
                                className="tileshop-content-back-img"
                                src={bundleback}
                                alt='Bundleback'/>
                        </div>
                        <div className="comingsoonmachine animate__animated animate__bounceIn animate__slow animate__delay-1s">
                            <img
                                className="comingsoonmachine-img"
                                src={comingsoon}
                                alt='comingsoon'/>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
};

export default MachinePopup;